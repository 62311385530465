<template>
  <div class="c-app flex-row align-items-center bg-yellow">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol lg="12" class="font260 text-center" style="margin-top: 20px">
          <strong>Beaming School</strong></CCol
        >
        <CCol
          lg="12"
          class="text-center text-white"
          style="margin-bottom: 20px"
        >
          <strong>Big Data Provider For School.</strong></CCol
        >
        <CCol lg="6">
          <CCardGroup>
            <CCard class="p-4 card-yellow">
              <CCardHeader class="bg-no-color">
                <i class="ri-user-5-line font260"></i
                ><strong>สำหรับนักเรียน:</strong>
              </CCardHeader>
              <CCardBody>
                <form id="formLogin" v-on:submit.prevent="login" method="POST">
                  <p class="text-muted">Sign In to your account</p>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1"
                        ><CIcon name="cil-user"
                      /></span>
                    </div>
                    <input
                      type="text"
                      name="txtUsername"
                      class="form-control"
                      placeholder="Username"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      required
                    />
                  </div>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1"
                        ><CIcon name="cil-lock-locked"
                      /></span>
                    </div>
                    <input
                      type="password"
                      name="txtPassword"
                      class="form-control"
                      placeholder="Password"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      required
                    />
                  </div>
                  <CRow>
                    <CCol col="12" class="text-right">
                      <button type="submit" class="btn btn-black btn-block">
                        login
                      </button>
                    </CCol>
                  </CRow>
                </form>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
        <CCol lg="12">
          <div class="text-center" style="margin-top:20px">
            <a
              href="https://play.google.com/store/apps/details?id=com.beamintechnology.beamingschool"
            >
              <svg
                class="kOqhQd"
                aria-hidden="true"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path fill="none" d="M0,0h40v40H0V0z"></path>
                <g>
                  <path
                    d="M19.7,19.2L4.3,35.3c0,0,0,0,0,0c0.5,1.7,2.1,3,4,3c0.8,0,1.5-0.2,2.1-0.6l0,0l17.4-9.9L19.7,19.2z"
                    fill="#EA4335"
                  ></path>
                  <path
                    d="M35.3,16.4L35.3,16.4l-7.5-4.3l-8.4,7.4l8.5,8.3l7.5-4.2c1.3-0.7,2.2-2.1,2.2-3.6C37.5,18.5,36.6,17.1,35.3,16.4z"
                    fill="#FBBC04"
                  ></path>
                  <path
                    d="M4.3,4.7C4.2,5,4.2,5.4,4.2,5.8v28.5c0,0.4,0,0.7,0.1,1.1l16-15.7L4.3,4.7z"
                    fill="#4285F4"
                  ></path>
                  <path
                    d="M19.8,20l8-7.9L10.5,2.3C9.9,1.9,9.1,1.7,8.3,1.7c-1.9,0-3.6,1.3-4,3c0,0,0,0,0,0L19.8,20z"
                    fill="#34A853"
                  ></path>
                </g>
              </svg>
            </a>
          </div>
        </CCol>
      </CRow>
      <CRow style="margin-top: 20px">
        <CCol lg="12" class="text-center">
          <CButton
            class="btn-yellow"
            @click="$router.push({ path: './loginTeacher' })"
            >ฉันคือครู/ เจ้าหน้าที่</CButton
          >
        </CCol>
      </CRow>
    </CContainer>
    <!--- MODAL AREA-->
    <CModal
      :show.sync="darkModal"
      :no-close-on-backdrop="true"
      :centered="true"
      title="Modal title 2"
      size="lg"
      color="dark"
    >
      password หรือ username ไม่ถูกต้อง
      <template #header>
        <h6 class="modal-title">คำเตือน!!</h6>
        <CButtonClose @click="darkModal = false" class="text-white" />
      </template>
      <template #footer>
        <CButton @click="darkModal = false" color="danger">Discard</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      darkModal: false,
    };
  },
  methods: {
    login(event) {
      this.uploadingStat = 1;
      const form = document.getElementById(event.target.id);
      const formData = new FormData(form);
      formData.append("txtAction", "getStudent");
      this.axios
        .post(this.$hostUrl + "php_action/userAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          //console.log(response.data);
          if (response.data.userData == null) {
            this.darkModal = true;
          } else {
            this.$cookies.set("user", response.data.userData);
            //console.log("size: "+this.roughSizeOfObject(response.data));
            //this.$cookies.set("user", 9999);
            console.log(this.$cookies.get("user"));
            //this.$store.commit('setUserData', this.$cookies.get("user"));
            this.$router.push({ path: "../dashboard" });
          }
        })
        .finally(() => {});
    },
    loginTeacher(event) {
      this.uploadingStat = 1;
      const form = document.getElementById(event.target.id);
      const formData = new FormData(form);
      formData.append("txtAction", "get");
      this.axios
        .post(this.$hostUrl + "php_action/userAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.error == true) {
            this.darkModal = true;
          } else {
            this.$cookies.set("user", response.data.userData);
            //this.$store.commit('setUserData', this.$cookies.get("user"));
            this.$router.push({ path: "../dashboard" });
          }
        })
        .finally(() => {});
    },
    roughSizeOfObject(object) {
      var objectList = [];
      var stack = [object];
      var bytes = 0;

      while (stack.length) {
        var value = stack.pop();

        if (typeof value === "boolean") {
          bytes += 4;
        } else if (typeof value === "string") {
          bytes += value.length * 2;
        } else if (typeof value === "number") {
          bytes += 8;
        } else if (
          typeof value === "object" &&
          objectList.indexOf(value) === -1
        ) {
          objectList.push(value);

          for (var i in value) {
            stack.push(value[i]);
          }
        }
      }
      return bytes;
    },
  },
};
</script>
